// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #EF4694;
  --ion-color-primary-rgb: 239, 70, 148;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #EF4694;
  --ion-color-primary-tint: #c03b71;

  /** secondary **/
  --ion-color-secondary: #11B172;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #11B172;
  --ion-color-secondary-tint: #1b5f3d;

  /** tertiary **/
  --ion-color-tertiary: #A855A1;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #A855A1;
  --ion-color-tertiary-tint: #A855A1;

  --ion-color-quaternary: #612C5C;
  --ion-color-quaternary-rgb: 97,44,92;
  --ion-color-quaternary-contrast: #ffffff;
  --ion-color-quaternary-contrast-rgb: 255,255,255;
  --ion-color-quaternary-shade: #552751;
  --ion-color-quaternary-tint: #71416c;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // facebook
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  --ion-font-family: "Montserrat", sans-serif;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-quaternary {
  --ion-color-base: var(--ion-color-quaternary);
  --ion-color-base-rgb: var(--ion-color-quaternary-rgb);
  --ion-color-contrast: var(--ion-color-quaternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary-shade);
  --ion-color-tint: var(--ion-color-quaternary-tint);
}

.alert-button.sc-ion-alert-md{
  display:block;
  width:100%;
  margin:0 auto;

}
.alert-button.sc-ion-alert-md {
  --background:#EF4694 ;
  background-color:#EF4694;
  color:white;
}

.alert-button.sc-ion-alert-md.secondary {
  --background:#222428 !important;
  background-color:#222428 !important;
  color:white !important;
}

.alert-button.sc-ion-alert-md.tertiary {
  --background:#11B172 !important;
  background-color:#11B172 !important;
  color:white !important;
}
.alert-button.sc-ion-alert-md.danger {
  --background:#f04141 !important;
  background-color:#f04141 !important;
  color:white !important;
}
.alert-button.sc-ion-alert-md.dark {
  --background:#222428 !important;
  background-color:#222428 !important;
  color:white !important;
}
.alert-button-group.sc-ion-alert-md{
  flex-wrap:wrap;
}
.alert-button.sc-ion-alert-md .alert-button-inner
{
  justify-content:center !important;
}
